//对于axios进行二次封装
import axios from "axios";
import { Message } from 'element-ui'
//底下的代码也是创建axios实例
let requests = axios.create({
  //基础路径
  baseURL: "/c-api/chain",
  //请求不能超过50S
  // timeout: 50000,
});
 
//请求拦截器（可以在请求发出去之前，做一些事情）
requests.interceptors.request.use((config) => {
  return config;
});
 
//响应拦截器（在数据返回之后，做一些事情）
requests.interceptors.response.use(
  (res) => {
    // const code = res.data.code || 200;
    //相应成功
    if (res.data.code === 200) {

      // Message.success('123')
      return res.data;
    } else {
      Message.error(res.data.msg)
      return Promise.reject('error')
    }
  },
  (err) => {
    Message.error(err)
    console.log(err)
    //响应失败
    // alert("服务器响应数据失败");
  }
);
export default requests;